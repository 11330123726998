import { DefaultButton, Icon, Modal, PrimaryButton } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { searchLocationByPoint } from "../../api/maps";
import { getPinIcon } from "../../assets/getPinIcon";
import countries from "../../countries.json";
import { useSelector } from "../../store/hooks";
import { HSpace } from "../../components/styled";
import { useFormikContext } from "formik";

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

export const MapModalWithGeoSearch = ({ isOpen, closeModal }: Props) => {
  const areBingMapsReady = useSelector((s) => s.bingMaps.areBingMapsReady);
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);
  const { setFieldValue, values } = useFormikContext() as any;
  const [loc, setLoc] = useState<{
    address: any | null;
    lat: number | null;
    long: number | null;
  }>({
    address: null,
    lat: null,
    long: null,
  });

  const [searchBox, setSearchBox] = useState("");
  const [searchBox2, setSearchBox2] = useState("");

  useEffect(() => {
    if (!areBingMapsReady) return;
    if (!isOpen) return;
    setTimeout(() => {
      var formvals = values;
      var center =
        formvals && formvals.latitude && formvals.longitude
          ? new Microsoft.Maps.Location(formvals.latitude, formvals.longitude)
          : null;

      window.map = new Microsoft.Maps.Map("#bing-map", {
        showLocateMeButton: false,
        navigationBarMode: Microsoft.Maps.NavigationBarMode.minified,
        center: center,
      });

      if (center) {
        var pin = new Microsoft.Maps.Pushpin(center, {
          title: formvals.locationName,
          icon: getPinIcon(30, 45, "#005aa1"),
        });
        window.map.entities.push(pin);
        setSearchBox(formvals.locationName);
        setSearchBox2(center.latitude + "," + center.longitude);
      }

      const onPlacePin = async (e: any) => {
        window.map.entities.clear();
        const pin = new Microsoft.Maps.Pushpin(
          new Microsoft.Maps.Location(
            e.location.latitude,
            e.location.longitude
          ),
          {
            icon: getPinIcon(30, 45, "#005aa1"),
            title: "",
            draggable: true,
          }
        );
        Microsoft.Maps.Events.addHandler(pin, "dragend", function (e) {
          onPlacePin(e);
        });
        window.map.entities.push(pin);

        const locRes = await searchLocationByPoint(
          e.location.latitude,
          e.location.longitude
        );

        pin.setOptions({
          title:
            locRes[0]?.address?.addressLine ||
            locRes[0]?.address?.locality ||
            "",
        });

        const lat = locRes[0]?.geocodePoints[0]?.coordinates[0];
        const long = locRes[0]?.geocodePoints[0]?.coordinates[1];
        setLoc({
          address: locRes[0]?.address,
          lat: lat,
          long: long,
        });

        setSearchBox(locRes[0]?.address.formattedAddress || "");
        setSearchBox2(lat ? lat + "," + long : "");
      };
      Microsoft.Maps.Events.addHandler(window.map, "click", onPlacePin);

      function suggestionSelected(result: any) {
        //Remove previously selected suggestions from the map.
        window.map.entities.clear();
        //Show the suggestion as a pushpin and center map over it.
        const pin = new Microsoft.Maps.Pushpin(result.location, {
          icon: getPinIcon(30, 45, "#005aa1"),
          title: result.title,
        });
        window.map.entities.push(pin);

        //window.map.setView({ bounds: result.bestView });
        window.map.setView({ center: result.location });

        setLoc({
          address: result.address,
          lat: result.location?.latitude,
          long: result.location?.longitude,
        });

        setSearchBox2(
          result.location.latitude + "," + result.location.longitude
        );
        setSearchBox(result.formattedAddress);
      }
      Microsoft.Maps.loadModule("Microsoft.Maps.AutoSuggest", function () {
        const manager = new Microsoft.Maps.AutosuggestManager({
          map: window.map,
        });
        manager.attachAutosuggest(
          "#searchBox",
          "#searchBoxContainer",
          suggestionSelected
        );
        const searchBox = document.getElementById("searchBox");
        if (searchBox) {
          searchBox.style.boxSizing = "border-box";
        }
      });
    }, 0);
  }, [areBingMapsReady, isOpen]);

  if (!isOpen) return null;

  const onKeyPress = async (e: any) => {
    let allow_char = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      ",",
      ".",
      "-",
    ];
    if (e.which === 13) {
      var vc = getCoordinate(e.target.value);
      if (vc) {
        var latitude = vc[0];
        var longitude = vc[1];
        var result = await searchLocationByPoint(latitude, longitude);
        if (result && result.length > 0) {
          var res = result[0];
          //Remove previously selected suggestions from the map.
          window.map.entities.clear();
          //Show the suggestion as a pushpin and center map over it.
          var center = new Microsoft.Maps.Location(vc[0], vc[1]);
          const pin = new Microsoft.Maps.Pushpin(center, {
            icon: getPinIcon(30, 45, "#005aa1"),
            title: res.address.formattedAddress,
          });
          window.map.entities.push(pin);

          window.map.setView({ center: center });
          setLoc({
            address: res.address,
            lat: latitude,
            long: longitude,
          });

          setSearchBox(res.address.formattedAddress);
        }
      }
    } else if (allow_char.indexOf(e.key) === -1) {
      e.preventDefault();
      return false;
    } else if (e.key === ".") {
      var count = (e.target.value.match(/\./g) || []).length;
      if (count >= 2) {
        e.preventDefault();
        return false;
      }
    } else if (e.key === "-") {
      var count = (e.target.value.match(/-/g) || []).length;
      if (count >= 2) {
        e.preventDefault();
        return false;
      }
    } else if (e.key === "," && e.target.value.indexOf(",") !== -1) {
      e.preventDefault();
      return false;
    }
  };

  const getCoordinate = (value) => {
    var parts = value.split(",");
    if (parts.length !== 2) {
      return null;
    }
    var p1 = parts[0].trim();
    var p2 = parts[1].trim();
    var ret1 = Number(p1);
    var ret2 = Number(p2);
    if (
      isNaN(ret1) ||
      ret1 < -90 ||
      ret1 > 90 ||
      isNaN(ret2) ||
      ret2 < -180 ||
      ret2 > 180
    ) {
      return null;
    }
    return [ret1, ret2];
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={() => {
        closeModal();
      }}
      isBlocking={false}
    >
      <Wrap>
        <SearchBoxContainer id="searchBoxContainer">
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon iconName="Search" />
            <SearchBox
              value={searchBox}
              onChange={(e) => setSearchBox(e.target.value)}
              type="text"
              id="searchBox"
              placeholder={t("ram.locationForm.mapSearchLocations")}
            />
          </div>
        </SearchBoxContainer>
      </Wrap>
      <HSpace />
      <div
        style={{
          position: "absolute",
          top: "18px",
          zIndex: "99",
          background: "white",
          width: "400px",
          left: "330px",
        }}
      >
        <SearchBoxContainer>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon iconName="GlobeFavorite" />
            <SearchBox
              value={searchBox2}
              onChange={(e) => setSearchBox2(e.target.value)}
              type="text"
              id="searchBox2"
              onKeyPress={onKeyPress}
              placeholder={t("ram.locationForm.mapSearchGeoCoordinate")}
            />
          </div>
        </SearchBoxContainer>
      </div>
      <div
        id="bing-map"
        style={{
          position: "relative",
          width: "900px",
          height: "570px",
        }}
      />
      <BtnsWrap>
        <PrimaryButton
          text={t("ram.locationForm.mapSave")}
          styles={{
            root: {
              marginRight: "5px",
            },
          }}
          onClick={() => {
            let countryCode;
            if (loc.address?.countryRegionISO2) {
              const tax =
                taxonomy.Country.byCode[
                  `Country.${loc.address?.countryRegionISO2}`
                ];
              if (tax) {
                countryCode = {
                  label: t(`Country.${loc.address?.countryRegionISO2}`),
                  value: tax.id,
                };
              }
            }
            if (loc.address?.countryRegion) {
              let countryRegionCode;
              for (const [key, countryName] of Object.entries(countries)) {
                if (loc.address?.countryRegion === countryName) {
                  countryRegionCode = key;
                }
              }
              if (countryRegionCode !== undefined) {
                const tax =
                  taxonomy.Country.byCode[`Country.${countryRegionCode}`];
                if (tax) {
                  countryCode = {
                    label: t(tax.code),
                    value: tax.id,
                  };
                }
              }
            }

            setFieldValue("locationName", loc.address?.formattedAddress);
            setFieldValue("address", loc.address?.formattedAddress);
            setFieldValue("city", loc.address?.locality);
            setFieldValue("postalCode", loc.address?.postalCode ?? "");
            setFieldValue("latitude", loc.lat);
            setFieldValue("longitude", loc.long);
            setFieldValue("countryCode", countryCode ?? null);
            closeModal();
          }}
          disabled={loc && !loc.address}
        />
        <DefaultButton
          text={t("ram.locationForm.mapClose")}
          onClick={closeModal}
        />
      </BtnsWrap>
    </Modal>
  );
};

const SearchBoxContainer = styled.div`
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  &:focus-within {
    outline: -webkit-focus-ring-color auto 1px;
  }
`;

const SearchBox = styled.input`
  width: 100%;
  padding: 5px;
  border: 0;
  outline: none;
`;
const Wrap = styled.div`
  position: absolute;
  top: 18px;
  z-index: 99;
  background: white;
  width: 300px;
  left: 20px;
`;
const BtnsWrap = styled.div`
  padding: 5px;
  margin: 5px 0px;
  text-align: right;
  position: absolute;
  right: 5px;
  bottom: 0;
  background: transparent;
  z-index: 101;
`;
