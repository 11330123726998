import { createSlice } from "@reduxjs/toolkit";
import { AppThunkPromise } from "./store";
import { createAsyncActions } from "./util";

export type BingMapsState = {
  areBingMapsLoading: boolean;
  areBingMapsReady: boolean;
};

const loadBingMapsActions = createAsyncActions<void, void, void>(
  "bingMaps/load"
);

export const loadBingMaps =
  (): AppThunkPromise<void> => async (dispatch, getState) => {
    const refreshInterval = 60 * 60 * 1000; // 1 hour in milliseconds

    const loadScript = () => {
      dispatch(loadBingMapsActions.loading());
      const script = document.createElement("script");
      script.src = `https://www.bing.com/api/maps/mapcontrol?&callback=getMap&key=${process.env.REACT_APP_BING_MAPS_API_KEY}`;
      document.head.appendChild(script);
      window.getMap = function () {
        dispatch(loadBingMapsActions.success());
      };
    };

    const removeScript = () => {
      const script = document.querySelector(
        `script[src*="bing.com/api/maps/mapcontrol"]`
      );
      if (script) {
        document.head.removeChild(script);
      }
    };

    const refreshToken = () => {
      removeScript();
      loadScript();
    };

    // Initial load
    loadScript();

    // Set interval to refresh token
    setInterval(refreshToken, refreshInterval);
  };

export const bingMapsSlice = createSlice({
  name: "bingMaps",
  initialState: {
    areBingMapsLoading: false,
    areBingMapsReady: false,
  } as BingMapsState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadBingMapsActions.loading, (s, a) => {
      s.areBingMapsLoading = true;
    });
    builder.addCase(loadBingMapsActions.success, (s, a) => {
      s.areBingMapsLoading = false;
      s.areBingMapsReady = true;
    });
  },
});

export const bingMapsReducer = bingMapsSlice.reducer;
