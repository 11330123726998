import { GlobalStyles, GrecoSpinner, ThemeProvider } from "@greco/components";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import React, { Suspense, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import styled, {
  ThemeProvider as StyledThemeProvider,
  createGlobalStyle,
} from "styled-components";
import "./App.scss";
import AppProvider from "./AppProvider";
import { getUserPhotoSmall } from "./api/GraphService";
import { UserDataLoader } from "./api/UserDataLoader";
import backgroundImage from "./assets/GrECoGraphicTriangles.svg";
import { AppHeader, UserHeader } from "./components/AppHeader";
import { NoAccess } from "./components/NoAccess";
import { IHeaderConfig, headerConfig } from "./config/headerConfig";
import { routes } from "./config/routes";
import { defaultCols } from "./pages/recommendations/cols";
import { loadBingMaps } from "./store/bingMaps";
import { loadClients } from "./store/clients/clients";
import { setClientsFilterBasedOnQueryParamAction } from "./store/filters/filters";
import { useSelector } from "./store/hooks";
import { loadLocationsActions } from "./store/locations/locations";
import { setColumnsRecommendations } from "./store/recommendations-page/recommendationsPage";
import * as RecommendationsColumnOptions from "./store/recommendationsColumnOptions";
import { loadReportsActions } from "./store/reports/reports";
import { AppDispatch, history } from "./store/store";
import {
  loadTaxonomyActions,
  loadTaxonomyUADActions,
} from "./store/taxonomy/taxonomy";
import { setIsLoadingUserRole } from "./store/userRole";
import { darkStyledTheme, styledTheme } from "./theme/index";

import { HistoryRouter } from "redux-first-history/rr6";
import { TooltipComponent } from "components/TooltipComponent";

interface IAppState {
  headerConfig: IHeaderConfig;
  panelIsOpen: boolean;
  user: any;
  error: string;
  loading: boolean;
  search: string;
  appInsights: ApplicationInsights;
}

export const App = () => {
  const [state, setState] = useState<IAppState>({
    headerConfig: headerConfig,
    panelIsOpen: false,
    user: {},
    error: "",
    loading: false,
    search: "",
    appInsights: null,
  });
  const [user, setUser] = useState<UserHeader | null>(null);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const hasRights = useSelector((s) => s.auth.hasRights);
  const userRole = useSelector((s) => s.userRole.userRole);

  const selectedColumns = useMemo(() => {
    if (userRole === "insurer") {
      return defaultCols.filter(
        (col) =>
          col.key !== "dateOfLastResponse" &&
          col.key !== "commentOfLastResponse" &&
          col.key !== "numberOfResponses" &&
          col.key !== "dateOfLastAssessment" &&
          col.key !== "commentOfLastAssessment" &&
          col.key !== "numberOfAssessments" &&
          col.key !== "lastAction"
      );
    } else {
      return defaultCols.filter(
        (col) =>
          col.key !== "dateOfLastResponseInsurer" &&
          col.key !== "commentOfLastResponseInsurer" &&
          col.key !== "numberOfResponsesInsurer" &&
          col.key !== "dateOfLastAssessmentInsurer" &&
          col.key !== "commentOfLastAssessmentInsurer" &&
          col.key !== "numberOfAssessmentsInsurer" &&
          col.key !== "lastActionInsurer"
      );
    }
  }, [userRole]);

  useEffect(() => {
    (async () => {
      dispatch(loadBingMaps());
      dispatch(loadTaxonomyActions.trigger());
      dispatch(setColumnsRecommendations(selectedColumns));
      dispatch(RecommendationsColumnOptions.setColumns(selectedColumns));
      dispatch(loadTaxonomyUADActions.trigger());
      dispatch(setIsLoadingUserRole(true));
      dispatch(loadClients.trigger());
      dispatch(loadLocationsActions.trigger());
      dispatch(loadReportsActions.trigger());
      dispatch(setClientsFilterBasedOnQueryParamAction());
    })();
  }, [selectedColumns]);
  const isDarkMode = Boolean(state.headerConfig.darkMode);
  const tooltipsStatus = Boolean(state.headerConfig.tooltipsStatus);

  useEffect(() => {
    setState({ ...state, panelIsOpen: false });
  }, [isDarkMode, tooltipsStatus]);

  useEffect(() => {
    const isDarkMode =
      localStorage.getItem("darkMode") === "true" ? true : false;
    localStorage.setItem("darkMode", String(isDarkMode));
    const tooltipsStatus =
      localStorage.getItem("tooltipsStatus") === "true" ? true : false;
    localStorage.setItem("tooltipsStatus", String(tooltipsStatus));
  }, []);

  useEffect(() => {
    (async () => {
      setIsUserLoading(true);
      const userData = await UserDataLoader.getUserData();
      const imgUrl = await getUserPhotoSmall();
      setUser({ ...userData, photoSmall: imgUrl, photoMedium: imgUrl });
      setIsUserLoading(false);
    })();
  }, []);

  return (
    <Suspense fallback={<GrecoSpinner />}>
      <HistoryRouter history={history}>
        <StyledThemeProvider
          theme={(isDarkMode ? darkStyledTheme : styledTheme) as any}
        >
          <ThemeProvider isDarkMode={Boolean(state.headerConfig.darkMode)}>
            <AppProvider tooltipsStatus={state.headerConfig.tooltipsStatus}>
              {hasRights && (
                <>
                  <AppHeader
                    key={Boolean(state.headerConfig.darkMode).toString()}
                    user={user}
                    tooltipsStatus={state.headerConfig.tooltipsStatus}
                    tooltipStatusChange={(tooltipsStatus) => {
                      const checked = Boolean(
                        tooltipsStatus.target.ariaChecked === "true"
                          ? false
                          : true
                      );
                      localStorage.setItem("tooltipsStatus", String(checked));
                      setState({
                        ...state,
                        headerConfig: {
                          ...state.headerConfig,
                          tooltipsStatus: checked,
                        },
                      });
                    }}
                    darkMode={(isDarkMode) => {
                      localStorage.setItem("darkMode", String(isDarkMode));
                      setState({
                        ...state,
                        headerConfig: {
                          ...state.headerConfig,
                          darkMode: isDarkMode,
                        },
                      });
                    }}
                  />
                  <AppWrapper
                    isDarkMode={isDarkMode}
                    isOpen={state.panelIsOpen}
                  >
                    <Routes>
                      {Object.keys(routes).map((key) => {
                        const route = routes[key];
                        return (
                          <Route
                            key={key}
                            path={route.path}
                            element={<route.component />}
                          />
                        );
                      })}
                      <Route
                        path="*"
                        element={<Navigate to="/clients" replace />}
                      />
                    </Routes>
                  </AppWrapper>
                  <ToastContainer />
                  <GlobalStyle />
                  <GlobalStyles />

                  {/* <TooltipComponent /> */}
                </>
              )}
            </AppProvider>
            {!hasRights && <NoAccess />}
          </ThemeProvider>
        </StyledThemeProvider>
      </HistoryRouter>
    </Suspense>
  );
};

const GlobalStyle = createGlobalStyle`
  body {
    background-color: white;
  }
  input:-internal-autofill-selected{
    background-color: white !important;
    background-image: none !important;
  }

  .Toastify__toast-container {
    padding: 0;

    .Toastify__toast--default {
    }
    .Toastify__toast--info {
    }
    .Toastify__toast--success {
      .Toastify__progress-bar {
        background-color: green;
      }
    }
    .Toastify__toast--warning {
      .Toastify__progress-bar {
        background-color: orange;
      }
    }
    .Toastify__toast--error {
      .Toastify__progress-bar {
        background-color: #c50f1f;
      }
    }
    .Toastify__toast--error {
    }
    .Toastify__toast {
      min-height: unset;
      padding: 0;
      border: 1px solid #ccc;
    }
    .Toastify__close-button {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      padding: 0 10px;
      color: black;
    }
  }
`;

const AppWrapper = styled.div<{ isDarkMode: boolean; isOpen: boolean }>`
  background-image: url(${backgroundImage});
  background-color: ${(props) => (props.isDarkMode ? "#151515" : "#f8f9fe")};
  /* position: absolute;
  overflow: auto; */
  top: 50px;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: auto;
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;
  width: ${(props) => (props.isOpen ? "calc(100% - 340px)" : "100%")};
`;
