import {
  Action,
  combineReducers,
  configureStore,
  getDefaultMiddleware,
  ThunkAction,
} from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { fork } from "redux-saga/effects";
import { authReducer } from "./auth";
import { bingMapsReducer } from "./bingMaps";
import { clientsPageReducer } from "./clients-page/clientsPage";
import { clientsPageSaga } from "./clients-page/sagas";
import { clientsReducer } from "./clients/clients";
import { clientsSaga } from "./clients/sagas";
import { filtersReducer } from "./filters/filters";
import { filtersSaga } from "./filters/sagas";
import { locationDefaultValuesReducer } from "./locationDefaultValues";
import { locationPageReducer, locationPageSaga } from "./locationPage";
import { locationsPageReducer } from "./locations-page/locationsPage";
import { locationsPageSaga } from "./locations-page/sagas";
import { locationsReducer } from "./locations/locations";
import { locationsSaga } from "./locations/sagas";

import { defaultWeightingsPageReducer } from "./defaultWeightings-page/defaultWeightingsPage";
import { defaultWeightingsPageSaga } from "./defaultWeightings-page/sagas";
import { defaultWeightingsReducer } from "./defaultWeightings/defaultWeightings";
import { defaultWeightingsSaga } from "./defaultWeightings/sagas";

import {
  recommendationPageReducer,
  recommendationPageSaga,
} from "./recommendationPage";
import {
  recommendationPanelReducer,
  recommendationPanelSaga,
} from "./recommendationPanel";
import { recommendationsPageReducer } from "./recommendations-page/recommendationsPage";
import { recommendationsPageSaga } from "./recommendations-page/sagas";
import { recommendationsReducer } from "./recommendations/recommendations";
import { recommendationsSaga } from "./recommendations/sagas";
import { recommendationsColumnOptionsReducer } from "./recommendationsColumnOptions";
import { reportPageReducer } from "./reportPage";
import { reportsPageReducer } from "./reports-page/reportsPage";
import { reportsPageSaga } from "./reports-page/sagas";
import { reportsReducer } from "./reports/reports";
import { reportsSaga } from "./reports/sagas";
import { settingsReducer, settingsSaga } from "./settings";
import { taxonomySaga } from "./taxonomy/sagas";
import { taxonomyReducer } from "./taxonomy/taxonomy";
import { userRoleReducer } from "./userRole";
import { usersReducer, usersSaga } from "./users";
import { usersInsurerReducer, usersInsurerSaga } from "./usersinsurer";

import { createReduxHistoryContext } from "redux-first-history";
import { defaultWeightingReducer } from "./defaultWeightingPage";

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() });

const reducer = combineReducers({
  locationsPage: locationsPageReducer,
  locations: locationsReducer,
  filters: filtersReducer,
  clients: clientsReducer,
  clientsPage: clientsPageReducer,
  reports: reportsReducer,
  reportsPage: reportsPageReducer,
  taxonomy: taxonomyReducer,
  recommendations: recommendationsReducer,
  recommendationsPage: recommendationsPageReducer,
  reportPage: reportPageReducer,
  recommendationPage: recommendationPageReducer,
  locationDefaultValues: locationDefaultValuesReducer,
  locationPage: locationPageReducer,
  userRole: userRoleReducer,
  bingMaps: bingMapsReducer,
  settings: settingsReducer,
  recommendationPanel: recommendationPanelReducer,
  recommendationsColumnOptions: recommendationsColumnOptionsReducer,
  auth: authReducer,
  users: usersReducer,
  usersinsurer: usersInsurerReducer,
  defaultWeightingsPage: defaultWeightingsPageReducer,
  defaultWeightings: defaultWeightingsReducer,
  defaultWeighting: defaultWeightingReducer,
  router: routerReducer,
});

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer,
  middleware: [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(routerMiddleware),
    sagaMiddleware,
  ],
});

export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = any;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export type AppThunkPromise<T> = ThunkAction<
  Promise<T>,
  RootState,
  null,
  Action<string>
>;

export type LoadStatus = "none" | "loading" | "success" | "error";

export function* rootSaga() {
  yield fork(taxonomySaga);
  yield fork(recommendationPageSaga);
  yield fork(settingsSaga);
  yield fork(locationPageSaga);
  yield fork(filtersSaga);
  yield fork(clientsSaga);
  yield fork(clientsPageSaga);
  yield fork(locationsSaga);
  yield fork(locationsPageSaga);
  yield fork(reportsSaga);
  yield fork(reportsPageSaga);
  yield fork(recommendationsSaga);
  yield fork(recommendationsPageSaga);
  yield fork(recommendationPanelSaga);
  yield fork(usersSaga);
  yield fork(usersInsurerSaga);
  yield fork(defaultWeightingsSaga);
  yield fork(defaultWeightingsPageSaga);
}

sagaMiddleware.run(rootSaga);

export const history = createReduxHistory(store);
